import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import './css/App.css';
// import Web3Info from './components/Web3Info.js';
import Minter from './components/Minter.js';
import Dashboard from './components/Dashboard.js';
import Register from "./components/Register";
import Login from "./components/Login";
import ArtDetails from './components/ArtDetails.js';
import {auth, getUserWallet, logout} from "./util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
// import { useWeb3 } from '@openzeppelin/network/react';
import {getBalance, transferNFT} from "./util/interact.js";


function App() {
  const [wallet, setWallet] = useState("");
  const [balance, setBalance] = useState("");
  const [user] = useAuthState(auth);
  // const web3Context = useWeb3(`wss://mainnet.infura.io/ws/v3/${infuraProjectId}`);

  const getB = async () => {
    if (wallet == undefined || wallet === '') {
      const wallet = await getUserWallet(user.uid);
      setWallet(wallet);
      const b = await getBalance(wallet);
      setBalance(b);
      return true;

    }
  };

  console.log(user);
  return (
    <div className="App">
      <div>
        <h1>probArt</h1>
        <header class="header">
          {(user) ?
            <div onClick={() => { logout() }}>Hello, {user.email}. </div> :
            ""}
          {(user && getB()) ?
            <div className={'smaller-text'}>Balance: {(balance/1000000000000000000).toFixed(3)} ETH</div> :
            ""}
        </header>
        {/*<Web3Info title="Web3 Info" web3Context={web3Context} />*/}
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/register" component={Register} />
            <Route path="/item/:contract/:tokenId">
              <ArtDetails/>
            </Route>
            <Route path="/mint">
              <Minter/>
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;
