import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { auth, getUserWallet } from "../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "../css/Login.css";
function Dashboard() {
  const [user, loading] = useAuthState(auth);
  const [wallet, setWallet] = useState("");
  const history = useHistory();
  useEffect(async () => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) history.replace("/");
    const wallet = await getUserWallet(user.uid);
    setWallet(wallet);
  }, [user, loading]);
  return (
    <div className="wrapper">
      <div className="wrapper-container">
        <div>
          Your wallet: <b className={'smaller-text'}>{wallet}</b>
        </div><br/>
        <a className={"button"} href={"https://faucet.ropsten.be/donate/" + wallet} target="_blank">
          Make a deposit
        </a>
        <a className={"button"} href="/item/0x10dfd4425efF23c29be1C50869A7dB352c94e83C/1"> Go to NFT Token 1</a>
      </div>
    </div>
  );
}
export default Dashboard;
