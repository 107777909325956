import { pinJSONToIPFS } from "./pinata.js";
import { saveTransaction, getAddressPrivateKey } from './firebase.js'
import axios from 'axios';
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const PRIVATE_KEY = process.env.PRIVATE_KEY; // My wallet private key (token owner, contract creator)

console.log('alchemyKey', alchemyKey);
const contractABI = require("../contract-abi.json");
const contractAddress = "0x10dfd4425efF23c29be1C50869A7dB352c94e83C";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);



export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 Write a message in the text-field above.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 Write a message in the text-field above.",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

async function loadContract() {
  return new web3.eth.Contract(contractABI, contractAddress);
}

export const mintNFT = async (senderAddress, url, name, description) => {
  if (url.trim() == "" || name.trim() == "" || description.trim() == "") {
    return {
      success: false,
      status: "❗Please make sure all fields are completed before minting.",
    };
  }

  //make metadata
  const metadata = new Object();
  metadata.name = name;
  metadata.image = url;
  metadata.description = description;

  // const pinataResponse = await pinJSONToIPFS(metadata);
  // if (!pinataResponse.success) {
  //   return {
  //     success: false,
  //     status: "😢 Something went wrong while uploading your tokenURI.",
  //   };
  // }
  // const tokenURI = pinataResponse.pinataUrl;

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  try {
    const nonce = await web3.eth.getTransactionCount(senderAddress, 'latest'); //get latest nonce
    console.log('nonce', nonce);
    //the transaction
    const tx = {
      'from': senderAddress,
      'to': contractAddress,
      'nonce': nonce,
      'gas': 500000,
      'maxPriorityFeePerGas': 1999999987,
      'data': window.contract.methods.addNewItem(senderAddress).encodeABI()
    };

    const signedTx = await web3.eth.accounts.signTransaction(tx, "8a4b87295b5a128a6dad604683e3c184ca686a0a62d4ec067d56d99e9b0a07a5");
    console.log('signedTx', signedTx);
    const txResp = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
    console.log('txResp', txResp);
    console.log('hash', txResp.transactionHash);
    // TODO: Token ID is hardcoded!!
    await saveTransaction('mint', 'addNewItem', contractAddress, 4, '0x0000000000000000000000000000000000000000', window.ethereum.selectedAddress, txResp.transactionHash)
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
        txResp.transactionHash,
    };
    } catch (error) {
      return {
        success: false,
        status: "😥 Something went wrong: " + error.message,
      };
    }


  // const transactionParameters = {
  //   to: contractAddress, // Required except during contract publications.
  //   from: window.ethereum.selectedAddress, // must match user's active address.
  //   data: window.contract.methods
  //     .addNewItem(window.ethereum.selectedAddress)
  //     .encodeABI(),
  // };
  //
  // try {
  //   const txHash = await window.ethereum.request({
  //     method: "eth_sendTransaction",
  //     params: [transactionParameters],
  //   });
  //   // TODO: Token ID is hardcoded!!
  //   await saveTransaction('mint', 'addNewItem', contractAddress, 2, '0x0000000000000000000000000000000000000000', window.ethereum.selectedAddress, txHash)
  //
  //   return {
  //     success: true,
  //     status:
  //       "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
  //       txHash,
  //   };
  // } catch (error) {
  //   return {
  //     success: false,
  //     status: "😥 Something went wrong: " + error.message,
  //   };
  // }
};

export const getNFTMetadata = async (tokenId) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  const uriObj = await window.contract.methods.uri(tokenId)
  let url = await window.contract.methods.uri(tokenId).call()
  url = url.replace('{id}', uriObj.encodeABI());
  console.log('token url: ' + url)
  const resp = await axios.get(url);
  console.log('Metadata: ' + resp.data.image);
  return resp.data;

};

export const transferNFT = async (tokenId, senderAddress, receiverAddress) => {
  // TODO: Implement GSN https://docs.openzeppelin.com/contracts/3.x/gsn
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);
  try {
    if (window.confirm("Are you sure? This exclusive painting costs a lot!")) {
      try {
        const balanceOfSenderBeforeTrx = await window.contract.methods.balanceOf(senderAddress, tokenId).call();
        const balanceOfReceiverBeforeTrx = await window.contract.methods.balanceOf(receiverAddress, tokenId).call();
        console.log('balanceOfSenderBeforeTrx', balanceOfSenderBeforeTrx);
        console.log('balanceOfReceiverBeforeTrx', balanceOfReceiverBeforeTrx);
        const nonce = await web3.eth.getTransactionCount(senderAddress, 'latest'); //get latest nonce
        //the transaction
        const tx = {
          'from': senderAddress,
          'to': contractAddress,
          'nonce': nonce + 1,
          'gas': 1000000,
          'maxPriorityFeePerGas': 1999999987,
          'data': window.contract.methods.safeTransferFrom(senderAddress, receiverAddress, tokenId, 1, []).encodeABI()
        };
        const privateKeyOfOwner = await getAddressPrivateKey(senderAddress);
        // 0x70279691b9096886d153db0c6978c647f53e9978b9ba49eea3727bb403e8e35d
        const signedTx = await web3.eth.accounts.signTransaction(tx, privateKeyOfOwner);
        const txResp = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);
        console.log('hash', txResp.transactionHash);
        const balanceOfSenderAfterTrx = await window.contract.methods.balanceOf(senderAddress, tokenId).call();
        const balanceOfReceiverAfterTrx = await window.contract.methods.balanceOf(receiverAddress, tokenId).call();
        console.log('balanceOfSenderAfterTrx', balanceOfSenderAfterTrx);
        console.log('balanceOfReceiverAfterTrx', balanceOfReceiverAfterTrx);

        await saveTransaction('transfer', 'safeTransferFrom', contractAddress, tokenId, senderAddress, receiverAddress, txResp.transactionHash);
        alert("Buying NFT token succeed!");
      } catch (ex) {
        alert('Something went wrong..')
        console.log("Esception during transferNFT, ", ex);
      }
    } else {
      alert("Wrong decision!");
    }

  } catch (error) {
    alert("Could not transfer NFT this time..");
    console.log("Error...");
    console.log(error);
  }

};

export const generateAccount = async () => {
  const account = await web3.eth.accounts.create();
  console.log("New account generated : ", account)
  return account;
};

export const getBalance = async (addr) => {
  const balance = await web3.eth.getBalance(addr);
  console.log(`Wallet ${addr} balance: ${balance}`)
  return balance;
};
