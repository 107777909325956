import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";

import {
  useHistory,
  useParams
} from "react-router-dom";
import { getNFTMetadata, transferNFT } from "../util/interact.js";
import { getTokenLastTransaction, auth, getUserWallet } from "../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Web3Info() {
  const [user, loading] = useAuthState(auth);
  const [wallet, setWallet] = useState("");
  let { tokenId, contract } = useParams();
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");
  const [image, setImage] = useState("");
  const [owner, setOwner] = useState("");
  const [txHash, setTxHash] = useState("");
  const history = useHistory();

  const transferNFTWrapper = async () => {
    if (wallet == undefined || wallet === '') {
      const wallet = await getUserWallet(user.uid);
      setWallet(wallet);
      await transferNFT(tokenId,  owner, wallet);
    }
  };

  useEffect(async () => {
    // if (loading) {
    //   // maybe trigger a loading screen
    //   return;
    // }
    if (user) {
      const wallet = await getUserWallet(user.uid);
      setWallet(wallet);
      alert('wallet ' + wallet);
    }
    const { author, name, image } = await getNFTMetadata(tokenId);
    setAuthor(author);
    setName(name);
    setImage(image);
    const lastTx = await getTokenLastTransaction(tokenId);
    setOwner(lastTx.toAddress);
    setTxHash(lastTx.transactionHash);
  }, []);

  return (
    <div className="wrapper">
      <div className="wrapper-container">
        <img src={image} className={'details-image'}/>
        {/*ID: <b>{tokenId}</b><br/>*/}
        Author: <b>{author}</b><br/>
        Name: <b>{name}</b> <br/>
        Price: <b>1000 EUR</b> <br/>
        Owner: <span className={'smaller-text'}><b>{owner}</b></span> <br/>
        <br/>
        <a
          className="button"
          target="_blank"
          href={'https://ropsten.etherscan.io/token/0x10dfd4425eff23c29be1c50869a7db352c94e83c?a=' + tokenId}
        >Token details in the Blockchain</a>
        <a
          className="button"
          target="_blank"
          href={'https://ropsten.etherscan.io/tx/' + txHash }
        >Tx details in the Blockchain</a>
        {(user) ?
        <button
          className="login__btn"
          onClick={() => transferNFTWrapper()}
        >Buy NFT</button> :
          <Link to="/">
            <button
              className="login__btn">Login to Buy</button>
          </Link>
        }
      </div>
    </div>
  );
}
