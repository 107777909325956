import firebase from "firebase";
import { generateAccount } from "./interact";
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDobf0ZCy964TtXEqI_ywWLgdnBP7a5m8k",
  authDomain: "decentralisedart.firebaseapp.com",
  databaseURL: "https://decentralisedart-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "decentralisedart",
  storageBucket: "decentralisedart.appspot.com",
  messagingSenderId: "1051325345260",
  appId: "1:1051325345260:web:c040ce07b0f36730ffa4c9",
  measurementId: "G-DWSJ1RTRV2"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = app.auth();
const db = app.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("users")
      .where("uid", "==", user.uid)
      .get();
    if (query.docs.length === 0) {
      await db.collection("users").add({
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
      const acc = await generateAccount();
      await saveUserWallet(user, acc.address, acc.privateKey);
    }
    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    await db.collection("users").add({
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });

    return user;

  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  console.log("logout");
  auth.signOut();
};

const isLoggedIn = () => {
  const user = auth.currentUser;
  console.log('user', user)
  debugger;
  return user != null;
};

const saveUserWallet = async (user, address, privateKey) => {
  try {
    await db.collection("wallets").add({
      user: user.uid,
      address: address,
      privateKey: privateKey,
    });
  } catch (err) {
    console.error(err);
  }
};

const getUserWallet = async (userId) => {
  const query = await db
    .collection("wallets")
    .where("user", "==", userId)
    .get();
  const data = await query.docs[0].data();
  return data ? data.address : 'Unknown';
};

const getAddressPrivateKey = async (address) => {
  const query = await db
    .collection("wallets")
    .where("address", "==", address)
    .get();
  const data = await query.docs[0].data();
  return data ? data.privateKey : '';
};

const saveTransaction = async (type, method, contractAddress, tokenId, senderAddress, receiverAddress, transactionHash) => {
  try {
    await db.collection("transactions").add({
      type: type,
      asset: 'token',
      method: method,
      tokenId: tokenId,
      contractAddress: contractAddress,
      fromAddress: senderAddress,
      toAddress: receiverAddress,
      transactionHash: transactionHash,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  } catch (err) {
    console.error(err);
  }
};

// TODO: refactor with Alchemy alchemy_getAssetTransfers
const getTransactionsOfToken = async (tokenId) => {
  let data = [];
  try {
    const query = await db
      .collection("transactions")
      .where("tokenId", "==", Number(tokenId))
      .orderBy("timestamp", "desc")
      .get();
    if (query.docs && query.docs.length > 0) {
      data = query.docs.map(it => it.data())
    }
  } catch (ex) {
    console.log('Exception during getTransactionsOfToken', ex);
    return data;
  }
  return data;
};

const getTokenLastTransaction = async (tokenId) => {
  const transactions = await getTransactionsOfToken(tokenId);
  if (transactions && transactions.length > 0) {
    return transactions[0];
  }
  return {
    toAddress: 'Unknown'
  };
};

// TODO: refactor with Alchemy alchemy_getAssetTransfers
// const getTokensOfAddress = async (address) => {
//   let data = [];
//   const query = await db
//     .collection("transactions")
//     .where("toAddress", "==", address)
//     .orderBy("timestamp", "desc")
//     .get();
//   if (query.docs && query.docs.length > 0) {
//     data = query.docs.map(it => it.data())
//   }
//   return data;
// };

export {
  auth,
  db,
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  saveUserWallet,
  getUserWallet,
  getAddressPrivateKey,
  sendPasswordResetEmail,
  saveTransaction,
  logout,
  isLoggedIn,
  getTokenLastTransaction,
  getTransactionsOfToken
};
